<nav
  class="navbar navbar-expand-lg navbar-light w-100 navigation {{(!isSticky) ? 'overlay-content' : ((getScreenWidth < mobileWidth)?'no-overlay-content-mobile':'no-overlay-content')}}"
  id="navbar" [ngClass]="{'sticky': isSticky}">
  <div class="container">
    <a *ngIf="!withNavBarLogo" class="navbar-brand font-weight-bold nav-link" routerLink="/">{{ ownerDetail.company_name }}</a>
    <a *ngIf="withNavBarLogo" class="navbar-brand font-weight-bold nav-link" routerLink="/">
      <div class="logo-container">
        <img *ngIf="logoUrl==DF_LOGO" class="logo-img" src="{{logoUrl}}">
        <img *ngIf="logoUrl!=DF_LOGO" class="logo-img" [src]="logoUrl">
      </div>
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar"
      aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="main-navbar" #mainNavbar>
      <ul class="navbar-nav mx-auto">
        <li class="nav-item" (click)="closeNavbar()">
          <a class="nav-link" routerLink="/">{{'label.home'|transloco}}</a>
        </li>

        <li class="nav-item" (click)="closeNavbar()">
          <a class="nav-link" routerLink="{{PATH_PUBLICATIONS}}">{{'label.publications'|transloco}}</a>
        </li>

        <li class="nav-item" (click)="closeNavbar()">
          <a class="nav-link" routerLink="{{PATH_ABOUT}}">{{'label.about-us'|transloco}}</a>
        </li>

        <li class="nav-item" (click)="closeNavbar()">
          <a class="nav-link" routerLink="{{PATH_CONTACT}}">{{'label.contact'|transloco}}</a>
        </li>

        <li *ngIf="getScreenWidth < mobileWidth" class="nav-item" (click)="closeNavbar()">
          <a class="nav-link" (click)="openLanguageModal()">{{'language.title'|transloco}}</a>
        </li>
        <!-- Pages -->
        <li *ngIf="getScreenWidth < mobileWidth" class="nav-item dropdown dropdown-slide">
          <a *ngIf="sessionIsOpen" class="nav-link dropdown-toggle" href="#" id="navbarDropdown4" role="button"
            data-delay="350" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{getCustomerName()}}
          </a>

          <a *ngIf="!sessionIsOpen" class="nav-link dropdown-toggle" href="#" id="navbarDropdown4" role="button"
            data-delay="350" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ 'label.login-invite' | transloco }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown4">
            <li *ngIf="sessionIsOpen"><a routerLink="{{PATH_MY_CUSTOMER}}" (click)="closeNavbar()">{{'label.profile'|transloco}}</a></li>
            <li *ngIf="sessionIsOpen"><a routerLink="{{PATH_PRODUCT}}" class="btn btn-small btn-transparent" (click)="closeNavbar()">{{'label.dashboard'|transloco}}</a></li>
            <li *ngIf="!sessionIsOpen"><a routerLink="{{PATH_LOGIN}}" class="btn btn-small btn-main btn-block" (click)="closeNavbar()">{{'login.title'|transloco}}</a></li>
            <li *ngIf="!sessionIsOpen && isProductSite"><a routerLink="{{PATH_REGISTER}}" class="btn btn-small btn-main btn-block" (click)="closeNavbar()">{{'register.title'|transloco}}</a></li>
            <li *ngIf="sessionIsOpen"><a (click)="closeSession()" class="btn btn-small btn-main btn-block">{{'label.close'|transloco}}</a></li>
          </ul>
        </li><!-- /Pages -->

      </ul>
    </div>
    <!-- Navbar-collapse -->

    <ul class="top-menu list-inline mb-0 d-none d-lg-block" id="top-menu">
      <li class="dropdown cart-nav dropdown-slide">
        <a href="#" class="nav-link dropdown-toggle ml-5" data-toggle="dropdown" data-hover="dropdown">
          {{'label.start'|transloco}}<i class="tf-ion-ios-arrow-down ml-3"></i>
        </a>
        <div class="dropdown-menu cart-dropdown">
          <li *ngIf="sessionIsOpen">
            <h3>{{getCustomerName()}}</h3>
          </li>
          <li *ngIf="sessionIsOpen"><a routerLink="{{PATH_MY_CUSTOMER}}">{{'label.profile'|transloco}}</a></li>
          <!--li *ngIf="sessionIsOpen"><a href="user/orders">Orders</a></li>
          <li *ngIf="sessionIsOpen"><a href="user/favorites">My favorites</a></li-->
          <li *ngIf="sessionIsOpen"><a routerLink="{{PATH_PRODUCT}}" class="btn btn-small btn-transparent">{{'label.dashboard'|transloco}}</a></li>
          <li *ngIf="sessionIsOpen && enableWebmailSiteLink"><a href="{{webmailSite}}" target="_blank" class="btn btn-small btn-transparent">{{'label.open-mail-site'|transloco}}</a></li>
          <div *ngIf="!sessionIsOpen" class="text-center cart-buttons mt-3">
            <a href="#" (click)="openLanguageModal()" class="dropdown-toggle btn btn-small btn-main-transparent btn-block" data-toggle="dropdown" data-hover="dropdown">
              {{'language.title'|transloco}}
            </a>
          </div>
          <div *ngIf="!sessionIsOpen" class="text-center cart-buttons mt-3">
            <a href="{{PATH_STORE}}" class="btn btn-small btn-main-transparent btn-block">{{'label.store'|transloco}}</a>
          </div>
          <div *ngIf="!sessionIsOpen" class="text-center cart-buttons mt-3">
            <a href="{{PATH_EE_REGISTER}}" class="btn btn-small btn-main-transparent btn-block">{{'register.title'|transloco}}</a>
          </div>
          <div *ngIf="!sessionIsOpen" class="text-center cart-buttons mt-3">
            <a routerLink="{{PATH_LOGIN}}" class="btn btn-small btn-main btn-block" data-toggle="dropdown" data-hover="dropdown">{{'login.title'|transloco}}</a>
          </div>
          <div *ngIf="!sessionIsOpen && isProductSite" class="text-center cart-buttons mt-3">
            <a routerLink="{{PATH_REGISTER}}" class="btn btn-small btn-main btn-block">{{'register.title'|transloco}}</a>
          </div>
          <li *ngIf="sessionIsOpen">
            <a routerLink="{{PATH_DOCS}}" class="btn btn-small btn-default btn-block">{{'label.documentation'|transloco}}</a>
            <a href="{{PATH_SERVICE_STATUS_ADMIN}}" target="_blank" class="btn btn-small btn-default btn-block">{{'label.service-status-admin'|transloco}}</a>
          </li>
          <div *ngIf="sessionIsOpen" class="text-center cart-buttons mt-3">
            <a (click)="closeSession()" class="btn btn-small btn-main btn-block">{{'label.close'|transloco}}</a>
          </div>
        </div>
      </li>
  </ul>
  </div>
</nav>
